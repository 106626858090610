import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditCategory = () => {
  const { id } = useParams(); // Fetch the category ID from the URL
  const navigate = useNavigate();

  // Dummy data for categories (simulating fetching data)
  const initialCategories = [
    {
      id: 1,
      name: "Rings",
      description: "Beautiful diamond and gold rings.",
      photo: "https://via.placeholder.com/50",
    },
    {
      id: 2,
      name: "Necklaces",
      description: "Elegant gold necklaces.",
      photo: "https://via.placeholder.com/50",
    },
    {
      id: 3,
      name: "Bracelets",
      description: "Stylish bracelets for every occasion.",
      photo: "https://via.placeholder.com/50",
    },
  ];

  // State to hold the current category's details
  const [category, setCategory] = useState({
    id: "",
    name: "",
    description: "",
    photo: "",
  });

  // Fetch category data by ID (simulated with initial data)
  useEffect(() => {
    const categoryToEdit = initialCategories.find((cat) => cat.id === parseInt(id));
    if (categoryToEdit) {
      setCategory(categoryToEdit);
    } else {
      alert("Category not found!");
      navigate("/admin/categories/manage"); // Redirect back if category doesn't exist
    }
  }, [id, navigate]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform update logic here (e.g., API call to update the category)
    alert("Category updated successfully!");
    navigate("/admin/categories/manage"); // Redirect back to the Manage Categories page
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Edit Category</h2>

      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Category Name</label>
          <input
            type="text"
            name="name"
            value={category.name}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            placeholder="Enter category name"
            required
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Description</label>
          <textarea
            name="description"
            value={category.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            placeholder="Enter category description"
            rows="4"
            required
          />
        </div>

        {/* Photo */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Photo URL</label>
          <input
            type="text"
            name="photo"
            value={category.photo}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md px-3 py-2"
            placeholder="Enter photo URL"
            required
          />
        </div>

        {/* Current Photo Preview */}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Current Photo</label>
          <img
            src={category.photo}
            alt={category.name}
            className="w-24 h-24 object-cover rounded-md"
          />
        </div>

        {/* Buttons */}
        <div className="flex justify-between">
          <button
            type="button"
            className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500"
            onClick={() => navigate("/admin/categories/manage")}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-[#00593E] text-white px-4 py-2 rounded-md hover:bg-green-700"
          >
            Update Category
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCategory;
