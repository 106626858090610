import axios from "axios";
import React, { useEffect, useState } from "react";

const UpdateAboutUs = () => {
  // Initial description (replace with API data if fetching from backend)
  const [description, setDescription] = useState([]);
  const [aboutId, setAboutId] = useState();

  const getAboutUs = async () => {
    const apiGetAboutUS = "https://api.rimsjewellery.com/api/about/view";
    const response = await axios.get(apiGetAboutUS);
    setDescription(response.data);
    setAboutId(response.data._id);
    console.log(response.data);
  };
  useEffect(() => {
    getAboutUs();
  }, []);
  // Handle form submission
  const handleSubmit = async () => {
    //  e.preventDefault();

    try {
      console.log("in try---------------", aboutId);
      if (!aboutId) {
        alert("ID is missing. Please provide a valid ID.");
        return;
      }
      alert(`Updated Description: ${description}`);

      const apiUpdateAboutUs = `https://api.rimsjewellery.com/api/about/update/${aboutId}`;
      const response = await axios.put(
        apiUpdateAboutUs,
        { description: description },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert("Description updated successfully!");
      } else {
        alert("Failed to update the description.");
      }
    } catch (error) {
      console.error("Error updating description:", error);
      alert("An error occurred while updating the description.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Update About Us
      </h2>
      <form>
        {/* Description field */}
        <div className="mb-6">
          <label
            htmlFor="description"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            About Us Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={description.description}
            onChange={(e) => setDescription(e.target.value)}
            rows="8"
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E]"
            placeholder="Enter the about us description here..."
          ></textarea>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          onClick={() => handleSubmit()}
          className="bg-[#00593E] text-white px-6 py-2 rounded-md hover:bg-[#004731] focus:outline-none"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateAboutUs;
