import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";

const ManageProduct = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); // For fetching category data
  const [editingProduct, setEditingProduct] = useState(null); // Track the product being edited
  const [editForm, setEditForm] = useState({
    name: "",
    categoryId: "",
    price: "",
    featuredImage: "",
  });

  // Fetch all products
  const getAllProducts = async () => {
    const apiGetProducts = "https://api.rimsjewellery.com/api/products/view";
    const response = await axios.get(apiGetProducts);
    setProducts(response.data);
  };

  // Fetch all categories
  const getAllCategories = async () => {
    const apiGetCategories = "https://api.rimsjewellery.com/api/categories/view";
    const response = await axios.get(apiGetCategories);
    setCategories(response.data);
  };

  useEffect(() => {
    getAllProducts();
    getAllCategories();
  }, []);

  // Handle delete product
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      const apiDeleteProduct = `https://api.rimsjewellery.com/api/products/delete/${id}`;
      await axios.delete(apiDeleteProduct);
      getAllProducts();
    }
  };

  // Handle edit button click
  const handleEditClick = (product) => {
    setEditingProduct(product._id);
    setEditForm({
      name: product.name,
      categoryId: product.categoryId._id, // Use categoryId for the form value
      price: product.price,
      featuredImage: product.featuredImage,
    });
  };

  // Handle edit form input changes
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle save edited product
  const handleSaveEdit = async () => {
    const apiEditProduct = `https://api.rimsjewellery.com/api/products/update/${editingProduct}`;
    await axios.put(apiEditProduct, editForm);
    setEditingProduct(null); // Close the edit form
    getAllProducts(); // Refresh product list
  };

  return (
    <div className="max-w-6xl mx-auto p-8 mt-10 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Manage Products</h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">ID</th>
              <th className="p-3 border border-gray-300">Featured Image</th>
              <th className="p-3 border border-gray-300">Product Name</th>
              <th className="p-3 border border-gray-300">Category</th>
              <th className="p-3 border border-gray-300">Price (₹)</th>
              <th className="p-3 border border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product._id} className="hover:bg-gray-100">
                {/* ID */}
                <td className="p-3 border border-gray-300">{product._id}</td>

                {/* Featured Image */}
                <td className="p-3 border border-gray-300">
                  <img
                    src={product.featuredImage}
                    alt={product.name}
                    className="w-16 h-16 object-cover rounded-md"
                  />
                </td>

                {/* Name */}
                <td className="p-3 border border-gray-300">
                  {editingProduct === product._id ? (
                    <input
                      type="text"
                      name="name"
                      value={editForm.name}
                      onChange={handleEditChange}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    product.name
                  )}
                </td>

                {/* Category */}
                <td className="p-3 border border-gray-300">
                  {editingProduct === product._id ? (
                    <select
                      name="categoryId"
                      value={editForm.categoryId}
                      onChange={handleEditChange}
                      className="w-full px-2 py-1 border rounded"
                    >
                      <option value="" disabled>
                        Select a category
                      </option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    product.categoryId ? product.categoryId.name : "No category"
                  )}
                </td>

                {/* Price */}
                <td className="p-3 border border-gray-300">
                  {editingProduct === product._id ? (
                    <input
                      type="number"
                      name="price"
                      value={editForm.price}
                      onChange={handleEditChange}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    `₹${product.price}`
                  )}
                </td>

                {/* Actions */}
                <td className="p-3 justify-center mt-4 flex items-center space-x-4">
                  {editingProduct === product._id ? (
                    <>
                      <button
                        onClick={handleSaveEdit}
                        className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingProduct(null)}
                        className="bg-gray-500 text-white px-3 py-1 rounded-md hover:bg-gray-600"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditClick(product)}
                        className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                      >
                        <FaEdit className="inline-block mr-1" />
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(product._id)}
                        className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                      >
                        <FaTrash className="inline-block mr-1" />
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageProduct;
