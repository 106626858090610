import React, { useState, useEffect } from "react";
import axios from "axios";

const AddProduct = () => {
  const [product, setProduct] = useState({
    categoryId: "",
    name: "",
    price: "",
    description: "",
    featuredImage: null,
    image2: null,
    image3: null,
  });

  const [categories, setCategories] = useState([
    { id: "1", name: "Rings" },
    { id: "2", name: "Necklaces" },
    { id: "3", name: "Bracelets" },
    { id: "4", name: "Earrings" },
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setProduct((prev) => ({ ...prev, [name]: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("categoryId", product.categoryId);
    formData.append("name", product.name);
    formData.append("price", product.price);
    formData.append("description", product.description);
    formData.append("image1", product.featuredImage);
    if (product.image2) formData.append("image2", product.image2);
    if (product.image3) formData.append("image3", product.image3);

    // Debug: Log FormData contents
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await axios.post(
        "https://api.rimsjewellery.com/api/products/create",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Product added successfully:", response.data);
      alert("Product added successfully!");
      setProduct({
        categoryId: "",
        name: "",
        price: "",
        description: "",
        featuredImage: null,
        image2: null,
        image3: null,
      });
    } catch (error) {
      console.error(
        "Error adding product:",
        error.response?.data || error.message
      );
      alert("Failed to add product. Please try again.");
    }
  };

  const getAllCategories = async () => {
    const apiGetCategories = "https://api.rimsjewellery.com/api/categories/view";
    const response = await axios.get(apiGetCategories);
    setCategories(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div className="max-w-3xl mx-auto bg-white p-8 mt-10 shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Add Product</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block font-semibold mb-2">Category</label>
          <select
            name="categoryId"
            value={product.categoryId}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2"
            required
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block font-semibold mb-2">Product Name</label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter product name"
            required
          />
        </div>

        <div>
          <label className="block font-semibold mb-2">Product Price</label>
          <input
            type="number"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter product price"
            required
          />
        </div>

        <div>
          <label className="block font-semibold mb-2">
            Product Description
          </label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter product description"
            rows="4"
            required
          ></textarea>
        </div>

        <div>
          <label className="block font-semibold mb-2">Featured Image</label>
          <input
            type="file"
            name="featuredImage"
            onChange={handleFileChange}
            className="w-full border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <div>
          <label className="block font-semibold mb-2">Product Image 2</label>
          <input
            type="file"
            name="image2"
            onChange={handleFileChange}
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>

        <div>
          <label className="block font-semibold mb-2">Product Image 3</label>
          <input
            type="file"
            name="image3"
            onChange={handleFileChange}
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-[#C8A165] text-white py-2 px-4 rounded-md hover:bg-[#a67a54] transition"
        >
          Add Product
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
