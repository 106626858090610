import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if email and password match the required credentials
    if (email === 'admin@rims.com' && password === '1234567890zxcvbnm') {
      // Redirect to the dashboard
      localStorage.setItem('adminEmail', email);
      navigate('/admin');
    } else {
      // Show an alert for invalid credentials
      alert('Invalid email or password');
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-gradient-to-br from-[#00593E] to-[#C8A165]">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8 mx-4 sm:mx-6 lg:mx-0">
        <div className="flex justify-center mb-6">
          <img src="rims_logo.jpg" alt="RIMS JEWELLERY" className="h-16" />
        </div>
        <h2 className="text-2xl font-bold text-center text-[#00593E]">Admin Login</h2>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#00593E] focus:outline-none"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#00593E] focus:outline-none"
              placeholder="Enter your password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#00593E] text-white font-medium py-2 px-4 rounded-lg hover:bg-[#C8A165] transition duration-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
