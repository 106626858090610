import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";

const ManageCategory = () => {
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null); // Track the category being edited
  const [editForm, setEditForm] = useState({ name: "", description: "", photo: "" });

  const getAllCategories = async () => {
    const apiGetCategories = "https://api.rimsjewellery.com/api/categories/view";
    const response = await axios.get(apiGetCategories);
    setCategories(response.data);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  // Handle delete category
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const apiDeleteCategory = `https://api.rimsjewellery.com/api/categories/delete/${id}`;
      await axios.delete(apiDeleteCategory);
      getAllCategories();
    }
  };

  // Handle edit button click
  const handleEditClick = (category) => {
    setEditingCategory(category._id);
    setEditForm({ name: category.name, description: category.description, photo: category.photo });
  };

  // Handle edit form input changes
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle save edited category
  const handleSaveEdit = async () => {
    const apiEditCategory = `https://api.rimsjewellery.com/api/categories/update/${editingCategory}`;
    await axios.put(apiEditCategory, editForm);
    setEditingCategory(null); // Close the edit form
    getAllCategories(); // Refresh categories
  };

  return (
    <div className="max-w-5xl mx-auto mt-10 bg-white p-8 shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Manage Categories</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300 text-left">ID</th>
              <th className="p-3 border border-gray-300 text-left">Photo</th>
              <th className="p-3 border border-gray-300 text-left">Name</th>
              <th className="p-3 border border-gray-300 text-left">Description</th>
              <th className="p-3 border border-gray-300 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id} className="hover:bg-gray-100">
                <td className="p-3 border border-gray-300">{category._id}</td>
                <td className="p-3 border border-gray-300">
                  <img
                    src={category.photo}
                    alt={category.name}
                    className="w-12 h-12 object-cover rounded"
                  />
                </td>
                <td className="p-3 border border-gray-300">
                  {editingCategory === category._id ? (
                    <input
                      type="text"
                      name="name"
                      value={editForm.name}
                      onChange={handleEditChange}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    category.name
                  )}
                </td>
                <td className="p-3 border border-gray-300">
                  {editingCategory === category._id ? (
                    <textarea
                      name="description"
                      value={editForm.description}
                      onChange={handleEditChange}
                      className="w-full px-2 py-1 border rounded"
                    />
                  ) : (
                    category.description
                  )}
                </td>
                <td className="p-3 border border-gray-300 text-center">
                  {editingCategory === category._id ? (
                    <>
                      <button
                        onClick={handleSaveEdit}
                        className="bg-green-500 text-white px-3 py-1 rounded-md mr-2 hover:bg-green-600"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingCategory(null)}
                        className="bg-gray-500 text-white px-3 py-1 rounded-md hover:bg-gray-600"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditClick(category)}
                        className="bg-blue-500 text-white px-3 py-1 rounded-md mr-2 hover:bg-blue-600"
                      >
                        <FaEdit className="inline-block" /> Edit
                      </button>
                      <button
                        onClick={() => handleDelete(category._id)}
                        className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                      >
                        <FaTrash className="inline-block" /> Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCategory;
