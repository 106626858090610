import React from "react";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6 text-[#00593E]">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Total Users */}
        <Link
          to="/admin/users"
          className="bg-[#C8A165] text-white text-center p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
        >
          <h2 className="text-lg font-semibold">Total Users</h2>
          <p className="text-2xl font-bold mt-2">(150)</p>
        </Link>

        {/* Total Orders */}
        <Link
          to="/admin/orders"
          className="bg-[#00593E] text-white text-center p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
        >
          <h2 className="text-lg font-semibold">Total Orders</h2>
          <p className="text-2xl font-bold mt-2">(200)</p>
        </Link>

        {/* Total New Orders */}
        <Link
          to="/admin/orders/new"
          className="bg-[#FF5733] text-white text-center p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
        >
          <h2 className="text-lg font-semibold">Total New Orders</h2>
          <p className="text-2xl font-bold mt-2">(25)</p>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;
