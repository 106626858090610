import axios from "axios";
import React, { useEffect, useState } from "react";

const UpdateContactUs = () => {
  // Initial contact details (replace with API data if fetching from backend)
  // const [contactDetails, setContactDetails] = useState({

  //   address: "1234 Jewelry Street, Gold City, 56789",
  //   email: "contact@rimsjewellery.com",
  //   mobile: "123-456-7890",
  //   timing: "Mon-Fri: 9 AM - 6 PM",
  // }
  // );

  const [contactDetails, setContactDetails] = useState({});

  // const [description, setDescription] = useState([]);
  const [contactId, setContactId] = useState();

  const getContactUs = async () => {
    const apiGetContactUS = "https://api.rimsjewellery.com/api/contact/view";

    try {
      const response = await axios.get(apiGetContactUS);

      if (response.data && response.data.length > 0) {
        const contact = response.data[0];
        setContactDetails(contact);
        setContactId(contact._id);
        console.log("data----------------", contactDetails);
      } else {
        console.error("No contact details found.");
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  useEffect(() => {
    getContactUs();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Contact ID in handleSubmit:", contactId);
      if (!contactId) {
        alert("ID is missing. Please provide a valid ID.");
        return;
      }

      // Ensure timings are in the correct format
      // const timingsArray = Array.isArray(contactDetails.timings)
      //     ? contactDetails.timings // Already an array
      //     : contactDetails.timings.split(",").map((item) => {
      //           const [day, time] = item.split(":").map((str) => str.trim());
      //           return { day, time };
      //       });

      // console.log("Timings array:", timingsArray);

      // API URL
      const apiUpdateContactUs = `https://api.rimsjewellery.com/api/contact/update/${contactId}`;

      // Make API call
      const response = await axios.put(
        apiUpdateContactUs,
        {
          address: contactDetails.address,
          email: contactDetails.email,
          mobileNumber: contactDetails.mobileNumber,
          timings: contactDetails.timings,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle Response
      if (response.status === 200) {
        alert("Data updated successfully!");
      } else {
        alert("Failed to update the data.");
      }
    } catch (error) {
      console.error("Error updating data:", error);
      alert("An error occurred while updating the data.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Update Contact Us
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Address */}
        <div className="mb-6">
          <label
            htmlFor="address"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Address:
          </label>
          <textarea
            id="address"
            name="address"
            value={contactDetails?.address}
            onChange={handleChange}
            rows="3"
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E]"
            placeholder="Enter address..."
          ></textarea>
        </div>

        {/* Email */}
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={contactDetails?.email}
            onChange={handleChange}
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E]"
            placeholder="Enter email address..."
          />
        </div>

        {/* Mobile Number */}
        <div className="mb-6">
          <label
            htmlFor="mobileNumber"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Mobile Number:
          </label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={contactDetails?.mobileNumber}
            onChange={handleChange}
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E]"
            placeholder="Enter mobile number..."
          />
        </div>

        {/* Timing */}
        <div className="mb-6">
          <label
            htmlFor="timings"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Timing:
          </label>
          <input
            type="text"
            id="timings"
            name="timings"
            value={contactDetails?.timings || ""}
            onChange={handleChange}
            className="w-full p-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E]"
            placeholder="Enter working hours..."
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-[#00593E] text-white px-6 py-2 rounded-md hover:bg-[#004731] focus:outline-none"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default UpdateContactUs;
