import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar"; // Sidebar component
import Dashboard from "./components/Dashboard"; // Dashboard component
import AddCategory from "./components/AddCategory"; // Import AddCategory component
import ManageCategory from "./components/ManageCategory"; // Import ManageCategory
import AddProduct from "./components/AddProduct"; // Import the component
import ManageProduct from "./components/ManageProduct"; // Import the component
import EditProduct from "./components/EditProduct"; // Import EditProduct component
import EditCategory from "./components/EditCategory"; // Import the component
import ManageOrders from "./components/ManageOrders"; // Import the component
import NewOrders from "./components/NewOrders"; // NewOrders component that displays only pending orders
import UpdateAboutUs from "./components/UpdateAboutUs"; // Import the component
import UpdateContactUs from "./components/UpdateContactUs"; // Import the component
import RegisteredUsers from "./components/RegisteredUsers"; // Import the component
import SearchOrders from "./components/SearchOrders"; // Import the component
import Inquiries from "./components/Inquiries";
import AdminLogin from "./components/AdminLogin";

const AppLayout = () => {
  const location = useLocation(); // Get current location
  const hideSidebarRoutes = ["/", "/admin/login"]; // Routes where Sidebar is hidden

  const shouldShowSidebar = !hideSidebarRoutes.includes(location.pathname);

  return (
    <div className="flex">
      {/* Conditionally render the Sidebar */}
      {shouldShowSidebar && <Sidebar />}

      {/* Main Content Area */}
      <div
        className={`flex-1 ${
          shouldShowSidebar ? "ml-64" : ""
        }  bg-gray-100 min-h-screen`}
      >
        <Routes>
          {/* Routes */}
          <Route path="/" element={<AdminLogin />} />
          <Route path="/admin" element={<Dashboard />} />
          <Route path="/admin/categories/add" element={<AddCategory />} />
          <Route path="/admin/categories/manage" element={<ManageCategory />} />
          <Route path="/admin/categories/edit/:id" element={<EditCategory />} />
          <Route path="/admin/products/add" element={<AddProduct />} />
          <Route path="/admin/products/manage" element={<ManageProduct />} />
          <Route path="/admin/products/edit/:id" element={<EditProduct />} />
          <Route path="/admin/orders" element={<ManageOrders />} />
          <Route path="/admin/orders/new" element={<NewOrders />} />
          <Route path="/admin/about" element={<UpdateAboutUs />} />
          <Route path="/admin/contact" element={<UpdateContactUs />} />
          <Route path="/admin/users" element={<RegisteredUsers />} />
          <Route path="/admin/search-order" element={<SearchOrders />} />
          <Route path="/admin/inquiries" element={<Inquiries />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppLayout />
    </Router>
  );
};

export default App;
