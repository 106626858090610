import React, { useState } from "react";
import axios from "axios";

const AddCategory = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    photo: null,
  });

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    photo: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
    setErrors({ ...errors, photo: "" }); // Clear errors
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form...");

    // Debug the formData before sending
    console.log("Initial formData:", formData);

    // Check server ping
    try {
      let ping = await axios.get("https://api.rimsjewellery.com/ping");
      console.log("Ping response:", ping.data);
    } catch (err) {
      console.error("Ping failed:", err.message);
      alert("Server is unreachable. Please try again later.");
      return;
    }

    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name || "");
      formDataToSend.append("description", formData.description || "");

      // Append photo with the correct key as expected by the server
      if (formData.photo) {
        formDataToSend.append("image", formData.photo);
      } else {
        console.warn("Photo is missing or invalid");
      }

      try {
        console.log("FormData contents:");
        formDataToSend.forEach((value, key) => console.log(`${key}:`, value));

        const response = await axios.post(
          "https://api.rimsjewellery.com/api/categories/create",
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("Category added:", response.data);
        alert("Category added successfully!");
        setFormData({ name: "", description: "", photo: null }); // Clear form
      } catch (error) {
        console.error(
          "Error adding category:",
          error.response?.data || error.message
        );
        alert("Failed to add category. Try again.");
      }
    } else {
      console.warn("Validation errors:", newErrors);
      setErrors(newErrors);
    }
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Category name is required.";
    if (!formData.description)
      newErrors.description = "Description is required.";
    if (!formData.photo) newErrors.photo = "Photo is required.";
    return newErrors;
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 bg-white p-8 shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Add Category</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Category Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`w-full border ${
              errors.name ? "border-red-500" : "border-gray-300"
            } rounded-md p-2 focus:ring-2 focus:ring-[#00593E]`}
            placeholder="Enter category name"
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
        </div>

        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className={`w-full border ${
              errors.description ? "border-red-500" : "border-gray-300"
            } rounded-md p-2 focus:ring-2 focus:ring-[#00593E]`}
            placeholder="Enter description"
            rows="4"
          />
          {errors.description && (
            <p className="text-red-500 text-sm">{errors.description}</p>
          )}
        </div>

        {/* Photo */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Upload Photo
          </label>
          <input
            type="file"
            name="photo"
            accept="image/*"
            onChange={handleFileChange}
            className={`w-full border ${
              errors.photo ? "border-red-500" : "border-gray-300"
            } rounded-md p-2 focus:ring-2 focus:ring-[#00593E]`}
          />
          {errors.photo && (
            <p className="text-red-500 text-sm">{errors.photo}</p>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#00593E] hover:bg-[#C8A165] text-white font-medium py-2 px-4 rounded-md transition-colors"
        >
          Add Category
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
