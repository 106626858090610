import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";

const NewOrders = () => {
  // Dummy data for orders
  const [orders, setOrders] = useState([]);

  const getAllOrders = async () => {
    const apiGetOrders = "https://api.rimsjewellery.com/api/orders/";
    const response = await axios.get(apiGetOrders);
    setOrders(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllOrders();
  }, []);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null); // Track dropdown visibility

  // Handle status change
  const handleStatusChange = (orderId, newStatus) => {
    setOrders(
      orders.map((order) =>
        order.orderId === orderId ? { ...order, status: newStatus } : order
      )
    );
    setIsDropdownOpen(null); // Close the dropdown after selection
  };

  // Function to get the status color
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "bg-purple-300 text-purple-800"; // Light purple
      case "Order Confirmed":
        return "bg-blue-300 text-blue-800"; // Blue
      case "Delivered":
        return "bg-green-300 text-green-800"; // Green
      case "Cancelled":
        return "bg-red-300 text-red-800"; // Red
      default:
        return "bg-gray-300 text-gray-800"; // Default
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8 mt-10 bg-white shadow-lg rounded-md">
      <h2 className="text-3xl font-bold text-[#00593E] mb-6">
        New Orders (Pending)
      </h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">Order ID</th>
              <th className="p-3 border border-gray-300">Order Number</th>
              <th className="p-3 border border-gray-300">Customer Name</th>
              <th className="p-3 border border-gray-300">Customer Mobile</th>
              <th className="p-3 border border-gray-300">Customer Email</th>
              {/* <th className="p-3 border border-gray-300">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {orders
              // .filter((order) => order.status === "Pending") // Only show pending orders
              .map((order) => (
                <tr
                  key={order.orderId}
                  className="hover:bg-gray-100 transition duration-200 ease-in-out"
                >
                  <td className="p-3 border border-gray-300">{order._id}</td>
                  <td className="p-3 border border-gray-300">
                    {order.ordernumber}
                  </td>
                  <td className="p-3 border border-gray-300">
                    {order.userid.firstname}
                  </td>
                  <td className="p-3 border border-gray-300">
                    {order.userid.mobilenumber}
                  </td>
                  <td className="p-3 border border-gray-300">
                    {order.userid.email}
                  </td>
                  {/* <td className="p-3 border border-gray-300 flex items-center space-x-2"> */}

                  {/* Status with dropdown toggle */}
                  {/* <div className="relative">
                      <button
                        
                        className={`p-2 border border-gray-300 rounded-md ${getStatusColor(
                          order.status
                        )} text-white`}
                      >
                        {order.status}
                      </button> */}

                  {/* Dropdown */}

                  {/* </div>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NewOrders;
