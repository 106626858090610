import React, { useEffect, useState } from "react";
import axios from "axios";

const SearchOrders = () => {
  // Dummy orders data
  const [orders, setOrders] = useState([
    // {
    //   orderId: 1,
    //   orderNumber: "ORD001",
    //   customerName: "John Doe",
    //   customerMobile: "1234567890",
    //   customerEmail: "john.doe@example.com",
    //   status: "Pending", // Default status
    // },
    // {
    //   orderId: 2,
    //   orderNumber: "ORD002",
    //   customerName: "Jane Smith",
    //   customerMobile: "9876543210",
    //   customerEmail: "jane.smith@example.com",
    //   status: "Pending",
    // },
    // {
    //   orderId: 3,
    //   orderNumber: "ORD003",
    //   customerName: "Michael Johnson",
    //   customerMobile: "5551234567",
    //   customerEmail: "michael.johnson@example.com",
    //   status: "Pending",
    // },
  ]);

  const getAllOrders = async () => {
    const apiGetOrders = "https://api.rimsjewellery.com/api/orders";
    const response = await axios.get(apiGetOrders);
    setOrders(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllOrders();
  }, []);

  // State to handle search and results
  const [searchId, setSearchId] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  // Handle Search
  const handleSearch = async () => {
    if (!searchId.trim()) {
      setSearchResult(null);
      alert("Please enter an Order ID to search.");
      return;
    }

    const apiSearchOrder = `https://api.rimsjewellery.com/api/orders/${searchId}`;

    const response = await axios.get(apiSearchOrder);

    console.log("result---------------------------", response);

    setSearchResult(response.data || null);

    if (!response) {
      alert("Order not found.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Search Orders</h2>

      {/* Search Input */}
      <div className="flex items-center mb-6">
        <input
          type="text"
          placeholder="Enter Order ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-full"
        />
        <button
          onClick={handleSearch}
          className="ml-4 px-4 py-2 bg-[#C8A165] text-white rounded-md hover:bg-[#b48557]"
        >
          Search
        </button>
      </div>

      {/* Search Result */}
      {searchResult ? (
        <div className="overflow-x-auto">
          <table className="w-full text-left border border-gray-300">
            <thead>
              <tr className="bg-[#C8A165] text-white">
                <th className="p-3 border border-gray-300">Order ID</th>
                <th className="p-3 border border-gray-300">Order Number</th>
                <th className="p-3 border border-gray-300">Customer Name</th>
                <th className="p-3 border border-gray-300">Customer Mobile</th>
                <th className="p-3 border border-gray-300">Customer Email</th>
                {/* <th className="p-3 border border-gray-300">Status</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-100">
                <td className="p-3 border border-gray-300">
                  {searchResult._id}
                </td>
                <td className="p-3 border border-gray-300">
                  {searchResult.ordernumber}
                </td>
                <td className="p-3 border border-gray-300">
                  {searchResult.userid.firstname} {searchResult.userid.lastname}
                </td>
                <td className="p-3 border border-gray-300">
                  {searchResult.userid.mobilenumber}
                </td>
                <td className="p-3 border border-gray-300">
                  {searchResult.userid.email}
                </td>
                {/* <td className="p-3 border border-gray-300">
                  <span
                    className={`p-2 rounded ${
                      searchResult.status === "Pending"
                        ? "bg-purple-200 text-purple-800"
                        : searchResult.status === "Delivered"
                        ? "bg-green-200 text-green-800"
                        : searchResult.status === "Cancelled"
                        ? "bg-red-200 text-red-800"
                        : searchResult.status === "Confirmed"
                        ? "bg-blue-200 text-blue-800"
                        : ""
                    }`}
                  >
                    {searchResult.status}
                  </span> 
                </td>*/}
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        searchId && <></>
      )}
    </div>
  );
};

export default SearchOrders;
