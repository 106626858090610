import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditProduct = () => {
  const { id } = useParams(); // Fetch product ID from URL
  const navigate = useNavigate();

  // Dummy data (replace with actual API call to fetch product details)
  const [product, setProduct] = useState(null);
  const categories = ["Rings", "Necklaces", "Bracelets"]; // Example categories

  useEffect(() => {
    // Simulate fetching data for the product
    const fetchData = async () => {
      const dummyProduct = {
        id: id,
        category: "Rings",
        name: "Gold Ring",
        price: 500,
        description: "Beautiful gold ring.",
        featuredImage: "https://via.placeholder.com/100",
        image2: "https://via.placeholder.com/100",
        image3: "https://via.placeholder.com/100",
      };
      setProduct(dummyProduct);
    };
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate saving data (replace with API call)
    alert(`Product with ID: ${id} has been updated!`);
    navigate("/admin/products/manage"); // Redirect to Manage Products page
  };

  if (!product) {
    return <div>Loading...</div>; // Show loading until data is fetched
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white p-8 shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">Edit Product</h2>

      <form onSubmit={handleSubmit}>
        {/* Category */}
        <div className="mb-4">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <select
            id="category"
            name="category"
            value={product.category}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        {/* Product Name */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Product Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={product.name}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          />
        </div>

        {/* Price */}
        <div className="mb-4">
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
            Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={product.price}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={product.description}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          ></textarea>
        </div>

        {/* Featured Image */}
        <div className="mb-4">
          <label htmlFor="featuredImage" className="block text-sm font-medium text-gray-700">
            Featured Image URL
          </label>
          <input
            type="text"
            id="featuredImage"
            name="featuredImage"
            value={product.featuredImage}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          />
        </div>

        {/* Image 2 */}
        <div className="mb-4">
          <label htmlFor="image2" className="block text-sm font-medium text-gray-700">
            Image 2 URL
          </label>
          <input
            type="text"
            id="image2"
            name="image2"
            value={product.image2}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          />
        </div>

        {/* Image 3 */}
        <div className="mb-4">
          <label htmlFor="image3" className="block text-sm font-medium text-gray-700">
            Image 3 URL
          </label>
          <input
            type="text"
            id="image3"
            name="image3"
            value={product.image3}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#C8A165] focus:border-[#C8A165]"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="px-4 py-2 bg-[#00593E] text-white rounded-md hover:bg-[#C8A165] transition"
        >
          Update Product
        </button>
      </form>
    </div>
  );
};

export default EditProduct;
