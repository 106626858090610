import axios from "axios";
import React, { useEffect, useState } from "react";

const RegisteredUsers = () => {
  // Dummy data for registered users
  // const [users] = useState([
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     mobile: "123-456-7890",
  //     email: "john.doe@example.com",
  //     registeredDate: "2024-01-01",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     mobile: "987-654-3210",
  //     email: "jane.smith@example.com",
  //     registeredDate: "2024-02-15",
  //   },
  //   {
  //     id: 3,
  //     name: "Michael Johnson",
  //     mobile: "555-123-4567",
  //     email: "michael.johnson@example.com",
  //     registeredDate: "2024-03-20",
  //   },
  // ]);

  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    const apiGetUsers = "https://api.rimsjewellery.com/api/users/view";
    const response = await axios.get(apiGetUsers);
    setUsers(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <div className="max-w-6xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Registered Users
      </h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">ID</th>
              <th className="p-3 border border-gray-300">Name</th>
              <th className="p-3 border border-gray-300">Mobile Number</th>
              <th className="p-3 border border-gray-300">Email</th>
              <th className="p-3 border border-gray-300">Registered Date</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-gray-100">
                <td className="p-3 border border-gray-300">{user._id}</td>
                <td className="p-3 border border-gray-300">{`${user.firstname} ${user.lastname}`}</td>
                <td className="p-3 border border-gray-300">
                  {user.mobilenumber}
                </td>
                <td className="p-3 border border-gray-300">{user.email}</td>
                <td className="p-3 border border-gray-300">{user.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegisteredUsers;
