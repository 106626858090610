import React from "react";
import { Link } from "react-router-dom";
import { FaTachometerAlt, FaListAlt, FaBox, FaShoppingCart, FaUsers, FaSearch, FaInfoCircle } from "react-icons/fa";
import { GiPriceTag } from "react-icons/gi";

const Sidebar = () => {
  return (
    <div className="h-full fixed w-64 bg-[#00593E] text-white">
      <div className="p-6 text-xl font-bold text-center border-b-2 border-[#C8A165]">
        <h1>RIMS | Admin</h1>
      </div>

      <ul className="mt-4 space-y-3">
        {/* Dashboard */}
        <li>
          <Link to="/admin" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaTachometerAlt />
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Categories */}
        <li className="mt-6">
          <div className="text-lg font-semibold pl-4 text-[#C8A165]">Categories</div>
          <ul className="ml-4 space-y-2">
            <li>
              <Link to="/admin/categories/add" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <GiPriceTag />
                <span>Add Category</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/categories/manage" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <FaListAlt />
                <span>Manage Categories</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Products */}
        <li className="mt-6">
          <div className="text-lg font-semibold pl-4 text-[#C8A165]">Products</div>
          <ul className="ml-4 space-y-2">
            <li>
              <Link to="/admin/products/add" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <FaBox />
                <span>Add Product</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/products/manage" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <FaBox />
                <span>Manage Products</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Orders */}
        <li className="mt-6">
          <div className="text-lg font-semibold pl-4 text-[#C8A165]">Orders</div>
          <ul className="ml-4 space-y-2">
            <li>
              <Link to="/admin/orders" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <FaShoppingCart />
                <span>All Orders</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/orders/new" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
                <FaShoppingCart />
                <span>New Orders</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Inquiries */}
        <li>
          <Link to="/admin/inquiries" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaInfoCircle />
            <span>Inquiries</span>
          </Link>
        </li>

        {/* About Us */}
        <li>
          <Link to="/admin/about" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaInfoCircle />
            <span>About Us</span>
          </Link>
        </li>

        {/* Contact Us */}
        <li>
          <Link to="/admin/contact" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaInfoCircle />
            <span>Contact Us</span>
          </Link>
        </li>

        {/* Registered Users */}
        <li>
          <Link to="/admin/users" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaUsers />
            <span>Reg Users</span>
          </Link>
        </li>

        {/* Search Order */}
        <li>
          <Link to="/admin/search-order" className="flex items-center space-x-2 py-3 px-4 hover:bg-[#C8A165]">
            <FaSearch />
            <span>Search Order</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
