import axios from "axios";
import React, { useEffect, useState } from "react";

const ManageOrders = () => {
  // Dummy data for orders
  const [orders, setOrders] = useState([
    // {
    //   orderId: 1,
    //   orderNumber: "ORD001",
    //   customerName: "John Doe",
    //   customerMobile: "1234567890",
    //   customerEmail: "john.doe@example.com",
    //   status: "Pending", // Default status
    // },
    // {
    //   orderId: 2,
    //   orderNumber: "ORD002",
    //   customerName: "Jane Smith",
    //   customerMobile: "9876543210",
    //   customerEmail: "jane.smith@example.com",
    //   status: "Pending",
    // },
    // {
    //   orderId: 3,
    //   orderNumber: "ORD003",
    //   customerName: "Michael Johnson",
    //   customerMobile: "5551234567",
    //   customerEmail: "michael.johnson@example.com",
    //   status: "Pending",
    // },
  ]);

  const getAllOrders = async () => {
    const apiGetOrders = "https://api.rimsjewellery.com/api/orders/";
    const response = await axios.get(apiGetOrders);
    setOrders(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getAllOrders();
  }, []);

  // Handle status change
  const handleStatusChange = (orderId, newStatus) => {
    setOrders(
      orders.map((order) =>
        order.orderId === orderId ? { ...order, status: newStatus } : order
      )
    );
  };

  // Function to get the status color
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "bg-purple-300 text-purple-800"; // Light purple
      case "Order Confirmed":
        return "bg-blue-300 text-blue-800"; // Blue
      case "Delivered":
        return "bg-green-300 text-green-800"; // Green
      case "Cancelled":
        return "bg-red-300 text-red-800"; // Red
      default:
        return "bg-gray-300 text-gray-800"; // Default
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8 mt-10 bg-white shadow-lg rounded-md">
      <h2 className="text-3xl font-bold text-[#00593E] mb-6">Manage Orders</h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">Order ID</th>
              <th className="p-3 border border-gray-300">Order Number</th>
              <th className="p-3 border border-gray-300">Customer Name</th>
              <th className="p-3 border border-gray-300">Customer Mobile</th>
              <th className="p-3 border border-gray-300">Customer Email</th>
              {/* <th className="p-3 border border-gray-300">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr
                key={order.orderId}
                className="hover:bg-gray-100 transition duration-200 ease-in-out"
              >
                <td className="p-3 border border-gray-300">{order._id}</td>
                <td className="p-3 border border-gray-300">
                  {order.ordernumber}
                </td>
                <td className="p-3 border border-gray-300">
                  {order.userid.firstname}
                </td>
                <td className="p-3 border border-gray-300">
                  {order.userid.mobilenumber}
                </td>
                <td className="p-3 border border-gray-300">
                  {order.userid.email}
                </td>
                {/* <td className="p-3 border border-gray-300"> */}
                {/* Display status with color */}
                {/* <span
                    className={`inline-block px-4 py-1 rounded-full ${getStatusColor(order.status)} text-center w-full`}
                  >
                    {order.status}
                  </span> */}

                {/* Status dropdown */}
                {/* <select
                    value={order.status}
                    onChange={(e) => handleStatusChange(order.orderId, e.target.value)}
                    className="mt-2 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00593E] hover:bg-gray-50"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Order Confirmed">Order Confirmed</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancelled">Cancelled</option>
                  </select> */}
                {/* </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageOrders;
