import React, { useEffect, useState } from "react";
import axios from "axios";

const Inquiries = () => {
  // Dummy data for inquiries
  const [content, setContent] = useState("");

  const [inquiries, setInquiries] = useState([
    // {
    //   id: 1,
    //   fullName: "Alice Johnson",
    //   email: "alice.johnson@example.com",
    //   message: "I want to know more about your diamond collection.",
    // },
    // {
    //   id: 2,
    //   fullName: "Bob Smith",
    //   email: "bob.smith@example.com",
    //   message: "Can I get a discount on bulk orders?",
    // },
    // {
    //   id: 3,
    //   fullName: "Charlie Brown",
    //   email: "charlie.brown@example.com",
    //   message: "Do you offer international shipping?",
    // },
  ]);
  const getInquiry = async () => {
    // console.log(content);

    const apiGetInquiry = "https://api.rimsjewellery.com/api/inquiries/";
    const response = await axios.get(apiGetInquiry);
    if (response.data && response.data.length > 0) {
      setInquiries(response.data);
    } else {
      setInquiries([]);
    }
  };
  useEffect(() => {
    getInquiry();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this inquiry?")) {
      // setCategories(categories.filter((category) => category.id !== id));
      // alert("Category deleted successfully!");
      console.log("id-----------------", id);
      const apiDeleteInquiry = `https://api.rimsjewellery.com/api/inquiries/delete/${id}`;
      const response = await axios.delete(apiDeleteInquiry);
      getInquiry();
    }
  };

  return (
    <div className="max-w-6xl mx-auto mt-10 p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold text-[#00593E] mb-6">
        Inquiries (from Contact Us page)
      </h2>

      <div className="overflow-x-auto">
        <table className="w-full text-left border border-gray-300">
          <thead>
            <tr className="bg-[#C8A165] text-white">
              <th className="p-3 border border-gray-300">ID</th>
              <th className="p-3 border border-gray-300">Full Name</th>
              <th className="p-3 border border-gray-300">Email</th>
              <th className="p-3 border border-gray-300">Message</th>
              <th className="p-3 border border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {inquiries.map((inquiry) => (
              <tr key={inquiry.id} className="hover:bg-gray-100">
                <td className="p-3 border border-gray-300">
                  {inquiry?._id || ""}
                </td>
                <td className="p-3 border border-gray-300">
                  {inquiry?.fullname || ""}
                </td>
                <td className="p-3 border border-gray-300">
                  {inquiry?.email || ""}
                </td>
                <td className="p-3 border border-gray-300">
                  {inquiry?.message || ""}
                </td>
                <td className="p-3 border border-gray-300">
                  <button
                    onClick={() => handleDelete(inquiry?._id)}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Inquiries;
